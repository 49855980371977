<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>
            {{ subscription.customer.full_name }} -
            {{ subscription.identifier }}
          </h1>
        </v-col>
        <v-col>
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom v-if="subscription.status === 'paused'">
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="success"
                  v-on="on"
                  class="ml-2"
                  :loading="changeStatus.loading"
                  @click="changeStatusPrompt('active')"
                >
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </template>
              <span>Resume Subscription</span>
            </v-tooltip>
            <v-tooltip bottom v-if="subscription.status === 'active'">
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  v-on="on"
                  class="ml-2"
                  :loading="changeStatus.loading"
                  @click="changeStatusPrompt('paused')"
                >
                  <v-icon>mdi-pause</v-icon>
                </v-btn>
              </template>
              <span>Pause Subscription</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                subscription.status === 'active' ||
                subscription.status === 'paused'
              "
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="error"
                  v-on="on"
                  class="ml-2"
                  :loading="changeStatus.loading"
                  @click="changeStatusPrompt('cancelled')"
                >
                  <v-icon>mdi-stop</v-icon>
                </v-btn>
              </template>
              <span>Cancel Subscription</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Subscription Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  depressed
                  @click="openEditDetailsForm()"
                  v-if="isEditable"
                >
                  <v-icon left>mdi-pencil</v-icon>Edit Details
                </v-btn>
              </v-toolbar>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>Status</th>
                    <td>
                      <v-chip
                        label
                        small
                        :color="getSubscriptionStatusColor(subscription.status)"
                        >{{
                          getSubscriptionStatusLabel(subscription.status)
                        }}</v-chip
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Customer</th>
                    <td>
                      <router-link
                        :to="{
                          name: 'module-paw-customers-individual',
                          params: { customerId: subscription.customer.id },
                        }"
                        >{{ subscription.customer.full_name }}</router-link
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Start Date</th>
                    <td>{{ subscription.formatted_dates.start_date }}</td>
                  </tr>
                  <tr>
                    <th>Delivery Volume</th>
                    <td>{{ subscription.volume }}kg</td>
                  </tr>
                  <tr>
                    <th>Delivery Frequency</th>
                    <td>
                      {{ subscription.frequency_value }}
                      {{ subscription.frequency_unit }}
                    </td>
                  </tr>
                  <tr>
                    <th>Products</th>
                    <td>
                      <ul>
                        <li>{{ subscription.product1.name }}</li>
                        <li v-if="subscription.product2">
                          {{ subscription.product2.name }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Renewal Charge</th>
                    <td>£{{ subscription.cost }} inc VAT</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Dog</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  depressed
                  @click="openEditDogForm()"
                  v-if="isEditable"
                >
                  <v-icon left>mdi-pencil</v-icon>Edit Dog
                </v-btn>
              </v-toolbar>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    {{ subscription.dog_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ subscription.breed.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar tile size="100" color="grey">
                  <v-img
                    v-if="subscription.profile_picture"
                    :src="subscription.profile_picture"
                  ></v-img>
                  <v-icon v-else>mdi-paw-off</v-icon>
                </v-list-item-avatar>
              </v-list-item>
              <v-divider></v-divider>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>Gender</th>
                    <td>{{ subscription.dog_gender }}</td>
                  </tr>
                  <tr>
                    <th>Date Of Birth</th>
                    <td>{{ subscription.formatted_dates.dog_dob }}</td>
                  </tr>
                  <tr>
                    <th>Target Weight</th>
                    <td>{{ subscription.target_weight }}kg</td>
                  </tr>
                  <tr>
                    <th style="border-bottom: none">Daily Allowance</th>
                    <td style="border-bottom: none">
                      <span class="subtitle-2">Recommended </span>
                      {{ subscription.grams_recommended }}g<br />
                      <div v-if="subscription.grams_entered !== null">
                        <span class="subtitle-2">Requested </span>
                        {{ subscription.grams_entered }}g
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Deliveries</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="deliveriesHeaders"
                :items="subscription.deliveries"
                no-data-text="No deliveries found"
              >
                <template v-slot:item.identifier="{ item }">
                  <router-link
                    :to="{
                      name: 'module-paw-deliveries-individual',
                      params: { deliveryId: item.id },
                    }"
                  >
                    {{ subscription.identifier }}{{ item.identifier }}
                  </router-link>
                </template>
                <template v-slot:item.cost="{ item }"
                  >£{{ item.cost }}</template
                >
                <template v-slot:item.products="{ item }">
                  <ul v-if="item.products">
                    <li
                      v-for="product in item.products"
                      v-bind:key="product.id"
                    >
                      {{ product.name }} ({{ product.pivot.volume }}kg)
                    </li>
                  </ul>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip
                    small
                    label
                    :color="getDeliveryStatusColor(item.delivery_status)"
                    >{{ getDeliveryStatusLabel(item.delivery_status) }}</v-chip
                  >
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        depressed
                        color="green lighten-4 green--text"
                        v-on="on"
                        :to="{
                          name: 'module-paw-deliveries-individual',
                          params: { deliveryId: item.id },
                        }"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View Delivery</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Audit Timeline</v-toolbar-title>
              </v-toolbar>
              <v-timeline
                dense
                class="pr-5"
                v-if="subscription.audits && subscription.audits.length"
              >
                <v-timeline-item
                  v-for="audit in subscription.audits"
                  v-bind:key="audit.id"
                  :icon="audit.action.icon"
                  :color="audit.action.colour"
                  fill-dot
                >
                  <v-card>
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="3">
                          <div class="caption">
                            {{ audit.happened_at_formatted }}
                          </div>
                          <div class="caption">
                            {{
                              audit.happened_by !== null
                                ? audit.happened_by.full_name
                                : "SYSTEM"
                            }}
                          </div>
                        </v-col>
                        <v-col>{{ audit.action.title }}</v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
              <v-card-text v-else
                >There has been no activity detected</v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="editDetailsForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveEditDetailsForm" method="post">
          <v-card-title class="headline"
            >Edit Subscription's Details</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-menu
              ref="editStartDate"
              v-model="editDetailsForm.startDateMenu"
              :close-on-content-click="false"
              max-width="290px"
              min-width="290px"
              :disabled="!subscription.start_date_changeable"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editDogStartDateFormatted"
                  label="Start Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :disabled="!subscription.start_date_changeable"
                  :hint="
                    !subscription.start_date_changeable
                      ? 'Cannot change as start date has now passed'
                      : null
                  "
                  :persistent-hint="!subscription.start_date_changeable"
                  :error="
                    editDetailsForm.errors.hasOwnProperty('fields.start_date')
                  "
                  :error-messages="editDetailsForm.errors['fields.start_date']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editDetailsForm.fields.start_date"
                :min="editDetailsForm.now"
                @input="editDetailsForm.startDateMenu = false"
              ></v-date-picker>
            </v-menu>
            <v-select
              label="Volume"
              v-model="editDetailsForm.fields.volume"
              :items="volumes"
              item-text="name"
              item-value="value"
              outlined
              @change="refreshPrice"
              append-outer-icon="mdi-weight-kilogram"
              :error="editDetailsForm.errors.hasOwnProperty('fields.volume')"
              :error-messages="editDetailsForm.errors['fields.volume']"
            ></v-select>
            <v-select
              label="Products"
              v-model="editDetailsForm.fields.products"
              :items="products"
              item-text="name"
              item-value="id"
              outlined
              multiple
              v-on:input="productLimiter"
              @change="refreshPrice"
              :error="editDetailsForm.errors.hasOwnProperty('fields.products')"
              :error-messages="editDetailsForm.errors['fields.products']"
            ></v-select>
            <v-row>
              <v-col>
                <v-text-field
                  label="Delivery Frequency Value"
                  v-model="editDetailsForm.fields.frequency_value"
                  type="number"
                  outlined
                  :error="
                    editDetailsForm.errors.hasOwnProperty(
                      'fields.frequency_value'
                    )
                  "
                  :error-messages="
                    editDetailsForm.errors['fields.frequency_value']
                  "
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  label="Delivery Frequency Unit"
                  v-model="editDetailsForm.fields.frequency_unit"
                  :items="deliveryFrequencyUnits"
                  item-text="name"
                  item-value="value"
                  outlined
                  :error="
                    editDetailsForm.errors.hasOwnProperty(
                      'fields.frequency_unit'
                    )
                  "
                  :error-messages="
                    editDetailsForm.errors['fields.frequency_unit']
                  "
                ></v-select>
              </v-col>
            </v-row>
            <v-text-field
              v-model="editDetailsForm.fields.cost"
              label="Renewal Charge"
              type="number"
              outlined
              prepend-inner-icon="mdi-currency-gbp"
              :loading="editDetailsForm.costLoading"
              :readonly="editDetailsForm.costDisabled"
              :error="editDetailsForm.errors.hasOwnProperty('fields.cost')"
              :error-messages="editDetailsForm.errors['fields.cost']"
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-icon
                      v-on="onTooltip"
                      @click="
                        editDetailsForm.costDisabled =
                          !editDetailsForm.costDisabled
                      "
                      >{{
                        editDetailsForm.costDisabled
                          ? "mdi-pencil-off"
                          : "mdi-pencil"
                      }}</v-icon
                    >
                  </template>
                  <span>{{
                    editDetailsForm.costDisabled
                      ? "Edit Renewal Charge"
                      : "Stop Editing Renewal Charge"
                  }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <v-checkbox
              v-model="editDetailsForm.fields.update_next_delivery"
              label="Do you want the next scheduled delivery to also be updated with these changes?"
              hide-details
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="editDetailsForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editDetailsForm.loading"
              :disabled="editDetailsForm.disabled"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDogForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveEditDogForm" method="post">
          <v-card-title class="headline"
            >Edit
            {{ pluralString(subscription.dog_name) }} Details</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Dog's Name"
                  v-model="editDogForm.fields.dog_name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <header>Gender</header>
                <v-radio-group
                  v-model="editDogForm.fields.dog_gender"
                  class="mt-0"
                  :error="
                    editDogForm.errors.hasOwnProperty('fields.dog_gender')
                  "
                  :error-messages="editDogForm.errors['fields.dog_gender']"
                >
                  <v-radio label="Female" value="female"></v-radio>
                  <v-radio label="Male" value="male"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="breeds"
                  item-text="name"
                  item-value="id"
                  v-model="editDogForm.fields.dog_breed"
                  label="Breed"
                  outlined
                  :error="editDogForm.errors.hasOwnProperty('fields.dog_breed')"
                  :error-messages="editDogForm.errors['fields.dog_breed']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="editDogFormDob"
                  v-model="editDogForm.dobMenu"
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editDogDobFormatted"
                      label="Date Of Birth"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      :error="
                        editDogForm.errors.hasOwnProperty('fields.dog_dob')
                      "
                      :error-messages="editDogForm.errors['fields.dog_dob']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editDogForm.fields.dog_dob"
                    @input="editDogForm.dobMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editDogForm.fields.target_weight"
                  label="Target Weight"
                  type="number"
                  append-icon="mdi-weight-kilogram"
                  outlined
                  :error="
                    editDogForm.errors.hasOwnProperty('fields.target_weight')
                  "
                  :error-messages="editDogForm.errors['fields.target_weight']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editDogForm.fields.grams_recommended"
                  label="Daily Allowance Recommended"
                  type="number"
                  append-icon="mdi-weight-gram"
                  readonly
                  outlined
                  :error="
                    editDogForm.errors.hasOwnProperty(
                      'fields.grams_recommended'
                    )
                  "
                  :error-messages="
                    editDogForm.errors['fields.grams_recommended']
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editDogForm.fields.grams_entered"
                  label="Daily Allowance Requested"
                  type="number"
                  append-icon="mdi-weight-gram"
                  outlined
                  :error="
                    editDogForm.errors.hasOwnProperty('fields.grams_entered')
                  "
                  :error-messages="editDogForm.errors['fields.grams_entered']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="editDetailsForm.fields.update_next_delivery"
              label="Do you want the next scheduled delivery to also be updated with these changes?"
              hide-details
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="editDogForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editDogForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="changeStatus.dialog"
      max-width="400px"
      @click:outside="resetChangeStatus()"
    >
      <v-card>
        <v-card-title class="headline">Change Status</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <div class="mb-5">
            Are you sure you want to change the status to
            {{ changeStatus.status }}?
          </div>

          <v-menu
            v-if="changeStatus.status == 'active'"
            ref="nextDeliveryDate"
            v-model="changeStatus.fields.deliveryMenu"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="changeStatus.fields.next_delivery_date"
                label="Next Delivery Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                :error="
                  changeStatus.errors.hasOwnProperty(
                    'fields.next_delivery_date'
                  )
                "
                :error-messages="
                  changeStatus.errors['fields.next_delivery_date']
                "
              ></v-text-field>
            </template>
            <v-date-picker
              :allowed-dates="allowedDates"
              :min="changeStatus.minDate"
              v-model="changeStatus.fields.next_delivery_date"
              @input="changeStatus.deliveryMenu = false"
              is-range
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetChangeStatus()"
            >No, Cancel</v-btn
          >
          <v-btn
            color="accent"
            text
            @click="changeStatusConfirmed()"
            :loading="changeStatus.loading"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { paw } from "../../mixins/";

export default {
  mixins: [paw],

  data() {
    return {
      allowedDates: (val) => {
        var dayOfWeek = new Date(val).getDay();
        return (
          dayOfWeek == 1 ||
          dayOfWeek == 2 ||
          dayOfWeek == 3 ||
          dayOfWeek == 4 ||
          dayOfWeek == 5
        );
      },
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Subscriptions",
          disabled: false,
          exact: true,
          to: {
            name: "module-paw-subscriptions",
            params: { id: this.$route.params.id },
          },
        },
      ],
      deliveriesHeaders: [
        { text: "Delivery Ref", value: "identifier" },
        { text: "Recipe(s)", value: "products" },
        { text: "Status", value: "status" },
        { text: "Cost", value: "cost" },
        {
          text: "Estimated Delivery",
          value: "formatted_dates.estimated_delivery",
        },
        { text: "Actions", value: "actions", align: "right" },
      ],

      deliveryFrequencyUnits: [
        {
          value: "days",
          name: "Days",
        },
        {
          value: "months",
          name: "Months",
        },
      ],
      editDetailsForm: {
        dialog: false,
        loading: false,
        disabled: false,
        errors: {},
        startDateMenu: false,
        now: new Date().toISOString().slice(0, 10),
        costLoading: false,
        costDisabled: true,
        fields: {
          start_date: null,
          volume: null,
          products: null,
          frequency_value: null,
          frequency_unit: null,
          cost: null,
          update_next_delivery: true,
        },
      },
      editDogForm: {
        dialog: false,
        loading: false,
        errors: {},
        dobMenu: false,
        fields: {
          dog_name: null,
          dog_gender: null,
          dog_breed: null,
          dog_dob: null,
          target_weight: null,
          grams_recommended: null,
          grams_entered: null,
          update_next_delivery: true,
        },
      },
      changeStatus: {
        status: null,
        minDate:
          new Date().getHours() < 12
            ? moment().add(1, "d").format("YYYY-MM-DD")
            : moment().add(2, "d").format("YYYY-MM-DD"),
        deliveryMenu: false,
        errors: {},
        dialog: false,
        loading: false,
        fields: {
          next_delivery_date: null,
        },
      },
    };
  },

  computed: {
    subscription() {
      return this.$store.state.paw["subscription"];
    },

    editDogDobFormatted() {
      return this.formatDate(this.editDogForm.fields.dog_dob);
    },

    editDogStartDateFormatted() {
      return this.formatDate(this.editDetailsForm.fields.start_date);
    },

    breeds() {
      return this.$store.state.paw["breeds"];
    },

    products() {
      return this.$store.state.paw["products"];
    },

    startDateHint() {
      return this.subscription.start_date_changeable
        ? "Cannot change start date as is has passed"
        : "";
    },

    isEditable() {
      return this.subscription.is_editable;
    },
  },

  methods: {
    openEditDogForm: function () {
      this.editDogForm.fields.dog_name = this.subscription.dog_name;
      this.editDogForm.fields.dog_gender =
        this.subscription.dog_gender.toLowerCase();
      this.editDogForm.fields.dog_breed = this.subscription.breed.id;
      this.editDogForm.fields.dog_dob = this.subscription.dog_dob;
      this.editDogForm.fields.target_weight = this.subscription.target_weight;
      this.editDogForm.fields.grams_recommended =
        this.subscription.grams_recommended;
      this.editDogForm.fields.grams_entered = this.subscription.grams_entered;
      this.editDogForm.dialog = true;
    },
    openEditDetailsForm: function () {
      this.editDetailsForm.fields.start_date = this.subscription.start_date;
      this.editDetailsForm.fields.frequency_value =
        this.subscription.frequency_value;
      this.editDetailsForm.fields.frequency_unit =
        this.subscription.frequency_unit;
      this.editDetailsForm.fields.volume = this.subscription.volume;
      this.editDetailsForm.fields.products = this.subscription.products.map(
        (p) => {
          return p.id;
        }
      );
      this.editDetailsForm.fields.cost = this.subscription.cost;
      this.editDetailsForm.dialog = true;
    },
    saveEditDogForm: function () {
      const appId = this.$route.params.id;
      const subscriptionId = this.$route.params.subscriptionId;
      let fields = this.editDogForm.fields;

      this.editDogForm.loading = true;
      this.editDogForm.errors = {};

      this.$store
        .dispatch("paw/editSubscription", {
          appId,
          subscriptionId,
          fields,
        })
        .then(() => {
          this.editDogForm.loading = false;
          this.editDogForm.dialog = false;
        })
        .catch((err) => {
          this.editDogForm.errors = err.response.data.errors;
          this.editDogForm.loading = false;
        });
    },
    saveEditDetailsForm: function () {
      const appId = this.$route.params.id;
      const subscriptionId = this.$route.params.subscriptionId;
      const fields = this.editDetailsForm.fields;

      this.editDetailsForm.loading = true;
      this.editDetailsForm.errors = {};

      this.$store
        .dispatch("paw/editSubscription", {
          appId,
          subscriptionId,
          fields,
        })
        .then(() => {
          this.editDetailsForm.loading = false;
          this.editDetailsForm.dialog = false;
        })
        .catch((err) => {
          this.editDetailsForm.errors = err.response.data.errors;
          this.editDetailsForm.loading = false;
        });
    },
    productLimiter: function (p) {
      const limit = this.editDetailsForm.fields.volume === 24 ? 2 : 1;

      if (p.length > limit) {
        p.pop();
      }
    },
    changeStatusPrompt: function (status) {
      this.changeStatus.status = status;
      this.changeStatus.dialog = true;
    },
    resetChangeStatus: function () {
      this.changeStatus.status = null;
      this.changeStatus.dialog = false;
      this.changeStatus.fields.next_delivery_date = null;
      this.changeStatus.loading = false;
    },
    changeStatusConfirmed: function () {
      const appId = this.$route.params.id;
      const subscriptionId = this.$route.params.subscriptionId;

      this.changeStatus.loading = true;

      this.$store
        .dispatch("paw/updateSubscriptionStatus", {
          appId,
          subscriptionId,
          status: this.changeStatus.status,
          next_delivery_date: this.changeStatus.fields.next_delivery_date,
        })
        .then(() => {
          this.resetChangeStatus();
        })
        .catch(() => {
          this.resetChangeStatus();
        });
    },
    refreshPrice: function () {
      this.editDetailsForm.costLoading = true;
      this.editDetailsForm.disabled = true;
      this.getUpdatedPrice(
        this.editDetailsForm.fields.volume,
        this.editDetailsForm.fields.products
      )
        .then((result) => {
          this.editDetailsForm.fields.cost = result;
          this.editDetailsForm.costLoading = false;
          this.editDetailsForm.disabled = false;
        })
        .catch(() => {
          this.editDetailsForm.costLoading = false;
          this.editDetailsForm.disabled = false;
        });
    },
  },
};
</script>
