export const paw = {
  data() {
    return {
      subscriptionStatuses: [
        { value: "active", label: "Active", color: "success" },
        { value: "paused", label: "Paused", color: "warning" },
        { value: "cancelled", label: "Cancelled", color: "error" },
      ],
      deliveryStatuses: [
        { value: "scheduled", label: "Scheduled", color: "blue white--text" },
        {
          value: "processing",
          label: "Processing",
          color: "orange white--text",
        },
        {
          value: "manually_paused",
          label: "Manually Paused",
          color: "red white--text",
        },
        {
          value: "payment_failed",
          label: "Payment Failed",
          color: "red white--text",
        },
        {
          value: "paid_dispatched",
          label: "Paid & Dispatched",
          color: "yellow white--text",
        },
        { value: "complete", label: "Complete", color: "green white--text" },
        { value: "cancelled", label: "Cancelled", color: "grey" },
        { value: "refunded", label: "Refunded", color: "red white--text" },
      ],
      volumes: [
        {
          value: 12,
          name: "12kg",
        },
        {
          value: 24,
          name: "24kg",
        },
      ],
    };
  },

  methods: {
    getSubscriptionStatusLabel: function (value) {
      const status = this.subscriptionStatuses.find((s) => s.value === value);

      return status.label;
    },

    getSubscriptionStatusColor: function (value) {
      const status = this.subscriptionStatuses.find((s) => s.value === value);

      return status.color;
    },

    getDeliveryStatusLabel: function (value) {
      const status = this.deliveryStatuses.find((s) => s.value === value);

      return status.label;
    },

    getDeliveryStatusColor: function (value) {
      const status = this.deliveryStatuses.find((s) => s.value === value);

      return status.color;
    },

    getUpdatedPrice: function (volume, products) {
      if (products.length < 1) {
        return Promise.resolve(0.0);
      }

      const appId = this.$route.params.id;
      const product_1 = products[0];
      const product_2 = products.length > 1 ? products[1] : null;

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("paw/fetchPrice", {
            appId,
            volume,
            product_1,
            product_2,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch(() => reject());
      });
    },

    getProductPrice: function (product, weight, type) {
      const index = product.prices.findIndex((p) => p.weight === weight);

      if (index === -1) {
        return null;
      }

      const productPrices = product.prices[index];

      return productPrices[type];
    },

    getProductPriceText: function (product, weight) {
      const index = product.prices.findIndex((p) => p.weight === weight);

      if (index === -1) {
        return "-";
      }

      const productPrices = product.prices[index];

      return (
        "ORD: £" +
        productPrices.price_normal +
        "<br />SUB: £" +
        productPrices.price_subscription
      );
    },
  },
};
